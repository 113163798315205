import { Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useRouter } from 'next/router'
import { CustomLightButton } from '../MuiStyledItems/CustomButtons'
import Dynamic100Vh from '../DynamicDiv100vh/Div100Vh'

const NoMatch = () => {
    let router = useRouter();
    const handleGoBackClick = () => {
        router.back();
    }
    return (
        <Dynamic100Vh style={{ width: "100%", backgroundColor: "black" }}>
            <Grid container direction={"column"} alignItems={"center"} height={"100%"} justifyContent={"space-around"}>
                <Stack justifyContent={"center"} alignItems={"center"}>
                    <Typography align='center' variant='customH1' sx={{ color: "white" }} pb={8}>404</Typography>
                    <Typography align='center' variant='customH3' sx={{ color: "white" }} pb={1}>ERROR</Typography>
                    <Typography align='center' variant='customBody' sx={{ color: "white" }}>The page you are looking for</Typography>
                    <Typography align='center' variant='customBody' sx={{ color: "white" }} pb={16}>is not here...</Typography>
                    <CustomLightButton onClick={handleGoBackClick} sx={{ width: "133px" }}>go back</CustomLightButton>
                </Stack>
            </Grid>
        </Dynamic100Vh>
    )
}

export default NoMatch
import { Stack } from '@mui/material';
import Box from '@mui/material/Box'
import { HatIcon, ShoesIcon, WatchIcon, GlassIcon} from '../../assets/icons/icons'
import { Category } from '../../shared/utils/enums';
import { BrandCategoryIconProps } from './BrandCategoryIcon.config';

const BrandCategoryIcon = (props: BrandCategoryIconProps) => {
  const categoryArray = ["hats","shoes","eyewear","watches"]
  const filteredArray = props.category.filter(value => categoryArray.includes(value.toLowerCase()));
  return (
    <Stack direction={"row"} spacing={0.5}>
    {filteredArray.map((item: string) => (
            <Box key={item} height={32} width={32} border={"1px solid black"} borderRadius={"50%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                {
                    item.toLowerCase() === Category.WATCHES ? <WatchIcon/> : item.toLowerCase() === Category.SHOES ? <ShoesIcon/> : item.toLowerCase() === Category.HATS ? <HatIcon/> : item.toLowerCase() === Category.EYEWEAR ? <GlassIcon/> : null
                }
            </Box>
        ))}
    </Stack>
  )
}

export default BrandCategoryIcon
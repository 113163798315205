import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import ProductCard from '../../components/ProductCard/ProductCard';
import TopBar from '../../components/TopBar/TopBar';
import { useInView } from 'react-intersection-observer'
import { Fragment, useEffect } from 'react';
import { CustomLightButton } from '../../components/MuiStyledItems/CustomButtons';
import { getVariantModelListByCategory, useGetVariantModelListByCategory } from '../../shared/query-hooks/VariantModelService';
import { VariantModelList, VariantModelListData } from '../../shared/utils/interfaces/VariantModelResponse';
import { useRouter } from 'next/router';
import NoMatch from '../../components/NoMatch/NoMatch';
import { GetServerSidePropsContext, GetStaticPaths } from 'next/types';
import Head from 'next/head';
import BrandCardMini from '../../components/BrandCard/BrandCardMini';
import { BrandList, BrandListData } from '../../shared/utils/interfaces/BrandResponse';
import { getBrands, useBrands } from '../../shared/query-hooks/BrandService';
import { isExternalVisit } from '../../shared/route';

interface CategoryListTopBarTextProps {
  category: string,
  modelCount: number,
}

const CategoryListTopBarText = (props: CategoryListTopBarTextProps) => {
  return (
    <Stack>
      <Typography align='center' variant='customBodyStrongCondensed'>{props.category}</Typography>
      <Typography align='center' variant='customBodyXsCondensed'>{props.modelCount} models available</Typography>
    </Stack>
  )
}

interface CategoryListProps {
  initialData: VariantModelList | BrandList,
}

const CategoryList = ({ initialData }: CategoryListProps) => {

  let router = useRouter();
  const { category } = router.query;

  const { ref, inView } = useInView()

  const { data, hasNextPage, fetchNextPage, isFetching, isFetchingNextPage, isFetchedAfterMount } = category === 'brands' ? useBrands(initialData as BrandList) : useGetVariantModelListByCategory(category?.toString().toUpperCase()!, initialData as VariantModelList);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView])

  const filterBrandAvailable = (data: BrandListData[]) => {
    return data.filter((item) => item.available_model_count > 0);
  }

  return (
    <>
      <Head>
        <title>TRYO - {category?.toString().toUpperCase()}</title>
        <meta name="description" content={`TRYO is the first virtual try-on experience with many brands and product categories that puts the whole shopping experience in the palm of your hand.`} />
        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_DOMAIN}/${category}`} />
      </Head>
      {category === "hats" || category === "shoes" || category === "eyewear" || category === "watches" || category === "trending" || category === "brands" ?
        <>
          <Grid container direction={"column"}>
            <Grid item>
              <TopBar destination={isExternalVisit() ? '/' : ''} textComponent={data?.pages[0] ? <CategoryListTopBarText category={category!} modelCount={data?.pages[0].total_count!} /> : null}></TopBar>
            </Grid>
            <Grid item container>
              <Grid item container xs={0} md={2} lg={2.5}></Grid>
              <Grid item container xs={12} md={8} lg={7} mt={{ xs: 0, md: 1 }} spacing={isSmall ? 2.5 : 3} justifyContent={"center"}>
                {
                  data?.pages.map((group, i) => (
                    <Fragment key={i}>
                      {category !== "brands" &&
                        group.data.map((variant_model) => (
                          <Grid item key={variant_model._id}>
                            <ProductCard variant_model={variant_model as VariantModelListData} />
                          </Grid>
                        ))
                      }

                      {category === "brands" &&
                        filterBrandAvailable(group.data as BrandListData[]).map((brand: BrandListData) => (
                          <Grid item key={brand._id}>
                            <BrandCardMini brand={brand as BrandListData} />
                          </Grid>
                        ))
                      }
                    </Fragment>
                  ))
                }
              </Grid>
              <Grid item container xs={0} md={2} lg={2.5}></Grid>
            </Grid>
            <Grid item container justifyContent={"center"} py={4}>
              {isFetching && !isFetchingNextPage ?
                <Typography variant='customBodyCondensed'>Fetching...</Typography> :
                <CustomLightButton onClick={() => fetchNextPage()} ref={ref} disabled={!hasNextPage}>{hasNextPage ? "load more" : "nothing more to see"}</CustomLightButton>
              }
            </Grid>
          </Grid>
        </>
        : <NoMatch />
      }
    </>
  )
}

// SSR Query
export async function getStaticProps(context: GetServerSidePropsContext) {
  // Prepare data for initial render
  const category = context.params!.category!.toString();

  console.log('received ssr category request');
  console.log('SSR Category: ' + category);

  let initialData = {};

  if (category === "brands") {
    initialData = await getBrands({ pageParam: 1 }, process.env.SSR_TOKEN);
  } else {
    initialData = await getVariantModelListByCategory({ pageParam: 1 }, context.params!.category!.toString().toUpperCase(), process.env.SSR_TOKEN);
  }

  return {
    props: {
      initialData: initialData
    },
    revalidate: 10
  }
}

export const getStaticPaths: GetStaticPaths<{ slug: string }> = async () => {

  // To not increase build time don't fetch all variant paths
  return {
    paths: [], //indicates that no page needs be created at build time
    fallback: 'blocking' //indicates the type of fallback
  }
}

export default CategoryList
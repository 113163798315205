import { Box, Stack, Typography, Grid } from '@mui/material'
import React from 'react'
import { logoURL } from '../../shared/constants';
import { BrandListData } from '../../shared/utils/interfaces/BrandResponse';
import BrandCategoryIcon from '../BrandCategoryIcon/BrandCategoryIcon'
import Image from 'next/image';
import styles from './BrandCard.module.scss'
import DefaultAnchor from '../Link/anchor/defaultAnchor';

interface BrandCardMiniProps {
  brand: BrandListData,
}

const BrandCardMini = (props: BrandCardMiniProps) => {

  return (
    <DefaultAnchor href={`/brands/${props.brand.brand_slug}`}>
      <Box width={167} height={202} bgcolor={"#ffffff"} borderRadius={"8px"} border={"1px solid #000000"} boxShadow={"0px 6px 0px #000000"} sx={{ cursor: "pointer" }}>
        <Grid container direction={"column"} height={"100%"}>
          <Grid item container justifyContent={"center"} alignItems={"center"} height={"50%"} className={styles.imageContainer}>
            <Image width={160} height={85} objectFit='contain' src={props.brand.thumbnail_file[0]?.url || logoURL} loading='lazy' />
          </Grid>
          <Grid item container justifyContent={"center"} height={"50%"} pt={1} >
            <Stack width={167} px={2}>
              <Typography noWrap align='center' variant='customBodyStrongCondensed'>{props.brand.brand_name}</Typography>
              <Typography noWrap align='center' variant='customBodyXsCondensed'>{props.brand.available_model_count} models available</Typography>
              <Grid container justifyContent={"center"} pt={1}>
                <Grid item>
                  <BrandCategoryIcon category={props.brand.tags} />
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </DefaultAnchor>
  )
}

export default BrandCardMini
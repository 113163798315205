import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Brand, BrandList } from "../utils/interfaces/BrandResponse";
import { baseURL, results_per_page } from "../constants";
import { getTokenFromCookie } from "./AuthService";

const getBrand = async (brand_id: string): Promise<Brand> => {
    const { data } = await axios.get<Brand>(
        baseURL + "/brands/" + brand_id,
        {
            headers: {
                "Authorization": `Bearer ${getTokenFromCookie()}`
            }
        }
    )
    console.log(data);
    return data;
}

export const useBrand = (brand_id: string) => {
    return useQuery<Brand, Error>(['getBrand', [brand_id]], () => getBrand(brand_id))
}

export const getBrandBySlug = async (brand_slug: string, optionalToken = ''): Promise<Brand> => {
    const { data } = await axios.get<Brand>(
        baseURL + "/brands/by-slug/" + brand_slug,
        {
            headers: {
                "Authorization": `Bearer ${optionalToken || getTokenFromCookie()}`
            }
        }
    )
    return data;
}

export const useBrandBySlug = (brand_slug: string) => {
    return useQuery<Brand, Error>(['getBrandBySlug', [brand_slug]], () => getBrandBySlug(brand_slug))
}

export const getBrands = async ({ pageParam = 1 }, optionalToken = ''): Promise<BrandList> => {
    const { data } = await axios.get(
        `${baseURL}/brands?per_page=${results_per_page}&page=${pageParam}&status=ACTIVE`,
        {
            headers: {
                "Authorization": `Bearer ${optionalToken || getTokenFromCookie()}`
            }
        });
    return data;
}

export const getAllBrands = async (optionalToken = ''): Promise<BrandList> => {
    const { data } = await axios.get(
        `${baseURL}/brands?status=ACTIVE`,
        {
            headers: {
                "Authorization": `Bearer ${optionalToken || getTokenFromCookie()}`
            }
        });
    return data;
}

export const useBrands = (initialData: BrandList) => {
    return useInfiniteQuery<BrandList, Error>(
        ['brands'],
        getBrands,
        {
            getNextPageParam: (pages) => {
                if (pages.data.length > 0) {
                    return pages.page + 1
                } else {
                    return undefined
                }
            },
            initialData: () => {
                const data = initialData
                if (data) {
                    return {
                        pageParams: [undefined],
                        pages: [data]
                    }
                }
            },
            refetchOnMount: false,
        }
    )
}